import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[7];

const JGreenspan = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Jared L. Greenspan" profile_key="jgreenspan">
      <Meta site={siteMetadata} title="Jared L. Greenspan" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Jared joined the firm following his call to the Bar of Ontario in June 2020. Along with his colleagues, he represents clients charged with criminal and quasi-criminal offences, as well as professional discipline matters. </p>

				<p>Jared earned a Bachelor of Arts from Queen’s University in 2007 where he studied European History. Following his graduation from Queen’s, he published a collection of short stories titled These Words Don’t Rhyme, and was a contributing writer to <i>The Globe and Mail</i>. While pursuing his writing career, Jared worked in a student capacity at the firm. </p>

				<p>He received his Juris Doctor from Osgoode Hall Law School in 2019. While attending law school, he published a regular column in <i>For the Defence</i>, the publication of the Criminal Lawyers’ Association, and was active in the Osgoode Hall Criminal Law Society. He completed his legal education as an articling student at Greenspan Humphrey Weinstein LLP.</p>

				<p>Jared is a member of the Criminal Lawyers’ Association, the Advocate’s Society, the Ontario Bar Association and the Toronto Lawyer’s Association.</p>

			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default JGreenspan
